import * as React from "react"
import { graphql } from "gatsby"

import FaqSection from '../Elements/FaqSection';

export default (props) => (
  <FaqSection {...props} className="p-6" />
);

export const query = graphql`
  fragment policyFaqs on PrismicPolicyDataBodyFaqs{
    id
    slice_type
    items {
      faq {
        document {
          ... on PrismicFaq {
            id
            data {
              question {
                text
                html
              }
              answer {
                text
                html
                richText
              }
            }
          }
        }
      }
    }
  }
`
