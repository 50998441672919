import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { PrismicRichText } from '@prismicio/react';

const Content = ({
  slice: {
    primary: {
      decoration,
      heading: { text: heading } = {},
      policy: { richText: policy } = {},
    },
  },
}) => (
  <div className="standard-para">
    <div className="absolute right-0 top-0">
      <GatsbyImage image={getImage(decoration)} />
    </div>
    <div className="flex flex-col items-center gap-24 p-6 pt-48 sm:pb-16 pb-8 relative">
      <div className="flex flex-col gap-6 md:gap-10 items-center text-blue">
        <h6 className="text-md md:text-lg" >POLICIES</h6>
        <h1 className="text-4xl md:text-6xl lg:text-8xl">
          {heading}
        </h1>
      </div>
      <div className="flex flex-col max-w-4xl">
        <PrismicRichText field={policy} />
      </div>
    </div>
  </div>
);

export default Content;

export const query = graphql`
  fragment policyContent on PrismicPolicyDataBodyContent {
    primary {
      decoration {
        gatsbyImageData(layout: FIXED)
      }
      heading {
        text
      }
      policy {
        richText
      }
    }
  }
`;
